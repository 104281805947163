<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card>
                    <v-expansion-panels>
                        <v-expansion-panel class="elevation-0">
                            <v-expansion-panel-header>فلتەرکردن</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-form ref="report" @submit.prevent>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                                                    transition="scale-transition" offset-y min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field @focus="$event.target.select()" v-model="from"
                                                            label="لە بەرواری" prepend-inner-icon="mdi-calendar" readonly
                                                            v-bind="attrs" v-on="on" outlined dense></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="from" @input="menu1 = false"></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col>
                                                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                                    transition="scale-transition" offset-y min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field @focus="$event.target.select()" v-model="to"
                                                            label="بۆ بەرواری" prepend-inner-icon="mdi-calendar" readonly
                                                            v-bind="attrs" v-on="on" outlined dense></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="to" @input="menu2 = false"></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col>
                                                <v-autocomplete v-model="category_id" :items="categories"
                                                    item-value="category_id" item-text="category_name" dense outlined
                                                    label="جۆر"></v-autocomplete>
                                            </v-col>
                                            <v-col>
                                                <v-btn @click="apply()" color="success"><v-icon>mdi-check</v-icon></v-btn>
                                                <v-btn @click="reset()" color="error"
                                                    class="mr-3"><v-icon>mdi-broom</v-icon></v-btn>
                                            </v-col>
                                            <v-col>

                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-data-table :items="expenses" :headers="headers">
                        <template v-slot:[`item.amount`]="{ item }">
                            {{ '$' + item.amount }}
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                            {{ new Date(item.date).toISOString().split('T')[0] }}
                        </template>

                        <template v-slot:[`body.append`]>
                            <tr>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center error white--text">{{ '$' + total.toLocaleString() }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            menu1: false,
            menu2: false,
            from: '',
            to: '',
            category_id: 0,
            categories: [],
            expenses: [],
            headers: [
                { text: 'خەرجکرا بۆ', value: 'detail', align: 'center' },
                { text: 'جۆر', value: 'category_name', align: 'center' },
                { text: 'بەرواری', value: 'date', align: 'center' },
                { text: 'بڕی', value: 'amount', align: 'center' },
            ]
        }
    },
    methods: {
        async getexpenses(from = '', to = '', category_id = 0) {
            console.log(from, to, category_id);
            const { data } = await this.axios.get('/report/expense', { params: { from, to, category_id } })
            this.expenses = data
        },
        async getCategories() {
            const { data } = await this.axios.get('/expense_category/forReference')
            this.categories = data
        },
        apply() {
            this.getexpenses(this.from, this.to, this.category_id)
        },
        reset() {
            this.from = ''
            this.to = '',
                this.category_id = 0
            this.getexpenses()
        }
    },
    created() {
        this.getexpenses()
        this.getCategories()
    },
    computed: {
        total() {
            let total = 0
            this.expenses.forEach(expense => total += expense.amount)
            return total
        }
    }
}
</script>

<style></style>